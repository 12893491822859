/* Add a black background color to the top navigation */
.topnav {
  background-color: #49385f;
  overflow: hidden;
/*  position:absolute;*/
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #8162a7;
  color: black;
}

/* Add a color to the active/current link */
.topnav a.active {
  background-color: #634b7f;
  color: white;
}

.topnav a.right {
  float: right;
}

body{
  background-color: #332E2F;
  font-family: 'Alegreya Sans', sans-serif;
}