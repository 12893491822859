button {
  background-color: #49385f; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 16px;
}

button:hover {
  background-color: #8162a7; /* Green */

}